
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		IonRefresher,
		IonRefresherContent
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getPostPath: function(item: any){
			if(item.blog_id){
				return "/blog/" + item.blog.name + "/post/" + item.blogpostid;
			} else {
				return "/topic/" + item.id;
			}
		},
		getPostType: function(item: any){
			if(item.blog_id){
				return "blog";
			} else {
				return "topic";
			}
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : '';
		}
	},
  setup() {
    const route = useRoute();
    const folder = "Лента записей сайта";
	const posts = ref({});
	const { token, getRichText, doLike } = useDataService();
	
	axios.get('https://beon.fun/api/v1/feed',{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
	posts.value = response.data
	));
    return { folder, posts, getRichText, doLike }
  }
}
