<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
		<ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>
         <ion-list>
            <ion-item class="ion-no-padding" v-for="item in posts" v-bind:key='item.id'>
                <div class="post">
                    <div class="post__header">
                        <span class="post__title" v-html="item.title"></span><span class="post__date">{{item.created_at}}</span>
                    </div>
                    <div class="post__body">
                        <div class="post__author" @click="() => $router.push(getUserPath(item.user))">
                            <img v-bind:src="getUserAvatar(item.user)">
                            <div class="post__username">{{item.user.nickname}}</div>
                            <div class="post__sign">{{item.user.sign}}</div>
                        </div>
                        <div class="post__text" v-html="getRichText(item.text, true)" @click="() => $router.push(getPostPath(item))" router-link="getPostPath(item)">
                        </div>
                    </div>
					<div class="like-wrapper" :class="{liked: item.liked.length}" @click="doLike(item, getPostType(item))">
						<fa icon="heart" class="fas-icon"></fa>
					</div>
					<div class="post__commcount">Комментариев: {{item.comments_count}}</div>
                </div>
            </ion-item>
          </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post__commcount {
	text-align: right;
}
.like-wrapper {
	margin-top: 1em;
}
.like-wrapper.liked {
	color: red;
}
.fas-icon, .editools span {
	height: 1.5rem;
	font-size: 1.5rem;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		IonRefresher,
		IonRefresherContent
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getPostPath: function(item: any){
			if(item.blog_id){
				return "/blog/" + item.blog.name + "/post/" + item.blogpostid;
			} else {
				return "/topic/" + item.id;
			}
		},
		getPostType: function(item: any){
			if(item.blog_id){
				return "blog";
			} else {
				return "topic";
			}
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : '';
		}
	},
  setup() {
    const route = useRoute();
    const folder = "Лента записей сайта";
	const posts = ref({});
	const { token, getRichText, doLike } = useDataService();
	
	axios.get('https://beon.fun/api/v1/feed',{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
	posts.value = response.data
	));
    return { folder, posts, getRichText, doLike }
  }
}
</script>